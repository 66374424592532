import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import SearchBox from './SearchBox';
import { logout } from '../actions/userActions';
import { useLocation } from 'react-router-dom';
import { register } from '../actions/userActions';
import { login } from '../actions/userActions';
import logo from '../assets/images/logo.png';
import axios from 'axios';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const locationURL = new URLSearchParams(location.search);
  const codeWechat = locationURL.get('code');
  let wechatUser;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  const getBaseCode = async () => {
    const result = await axios.get(
      `https://gzh.whtec.net/user/getcode?hostname=${process.env.REACT_APP_HOSTNAME}`
    );
    window.location.href = result.data;
  };
  const getInteractCode = async () => {
    const result = await axios.get(
      `https://gzh.whtec.net/user/getinteractcode?hostname=${process.env.REACT_APP_HOSTNAME}`
    );
    window.location.href = result.data;
  };

  const getWechatUser = async () => {
    if (!codeWechat) {
      await getBaseCode();
    } else {
      try {
        const result = await axios.post(
          `https://gzh.whtec.net/user/getUserinfo`,
          { code: codeWechat }
        );
        wechatUser = result.data;

        //verify email already registered

        // const emailAlreadyExist = await verifyEmail({
        //   email: wechatUser.openid + '@email.com',
        // });
        const emailAlreadyExist = await axios.get(
          `/api/users/extra/${wechatUser.openid}@email.com`
        );
        if (!emailAlreadyExist.data) {
          //getDetailWechatInfo
          if (!wechatUser.nickname) {
            return getInteractCode();
          }
          //register new user

          console.log('register...');
          dispatch(
            register(
              wechatUser.nickname,
              wechatUser.openid + '@email.com',
              '123456',
              wechatUser.headimgurl
            )
          );
        } else {
          //login user
          console.log('<<<login');
          dispatch(login(wechatUser.openid + '@email.com', '123456'));
          console.log(userInfo, '<<userInfo');
          if (userInfo.icon !== wechatUser.headimgurl) {
            axios
              .patch(`/api/users/icon/${userInfo.id}`, {
                icon: wechatUser.headimgurl,
              })
              .then((res) => console.log('updateUserIcon'))
              .catch((err) => console.log(err?.data?.message || err.error));
          }
          if (userInfo.name !== wechatUser.nickname) {
            axios
              .patch(`/api/users/name/${userInfo.id}`, {
                name: wechatUser.nickname,
              })
              .then((res) => console.log('updateUserName'))
              .catch((err) => console.log(err?.data?.message || err.error));
          }
        }

        return result.data;
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    let surfModel = navigator.userAgent;
    if (surfModel.toLowerCase().match(/micromessenger/i) === 'micromessenger') {
      async function middleWare() {
        wechatUser = await getWechatUser();
      }
      if (!userInfo) {
        middleWare();
      }
    }
  }, []);

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              {userInfo ? (
                <img src={userInfo.icon} alt='ProShop' width='40px' />
              ) : (
                <img src={logo} alt='ProShop' />
              )}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Route render={({ history }) => <SearchBox history={history} />} />
            <Nav className='ml-auto'>
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <i className='fas fa-shopping-cart'></i> Cart
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
